import React from 'react';
import { graphql } from 'gatsby';
import moment from 'moment';
import { css } from '@emotion/core';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { PageLink } from '../components/layout/style';
import { colors } from '../constants';

class Blog extends React.Component {
  constructor(props) {
    super(props);
    this.postList = [
      { year: 2019, list: props.data.blog2019.edges },
      { year: 2018, list: props.data.blog2018.edges },
    ];
  }

  render() {
    const siteTitle = this.props.data.site.siteMetadata.title;
    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title="Mygo's personal blog entries"
          keywords={[`blog`, `gatsby`, `javascript`, `react`, `mygo explains`]}
        />
        <h2
          css={css`
            text-align: center;
            display: block;
          `}
        >
          Personal Blog Posts
        </h2>
        <p>This is a list of all personal blog posts I've written. </p>
        <p>
          The content here is more diary like and contains honest expressions of
          the day to day that led to the current state of the blog. My intent
          was to fill it more regularly but it hasn't quite panned out that way
          (as of: 29 April 2019).
        </p>
        {this.postList.map(set => {
          return (
            <table
              key={`blog-post-title-${set.year}`}
              css={css`
                border: none;
                border-collapse: collapse;
                border-spacing: 0;
                text-align: left;
              `}
            >
              <tbody>
                {set.list.map(({ node }) => {
                  return (
                    <tr key={node.fields.slug}>
                      <td
                        css={css`
                          padding-right: 2rem;
                          font-style: italic;
                          font-size: 1em;
                          width: 10%;
                          white-space: nowrap;
                        `}
                      >
                        {moment.utc(node.frontmatter.date).format('DD/MM/YYYY')}
                      </td>
                      <td>
                        <PageLink
                          css={css`
                            color: ${colors.link.normal};
                          `}
                          to={node.fields.slug}
                        >
                          <span>{node.frontmatter.title}</span>
                        </PageLink>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          );
        })}
      </Layout>
    );
  }
}

export default Blog;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    blog2018: allMarkdownRemark(
      filter: {
        fields: { type: { eq: "blog" } }
        frontmatter: { date: { gt: "2018", lt: "2019" } }
      }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
            type
          }
          frontmatter {
            date
            title
          }
        }
      }
    }
    blog2019: allMarkdownRemark(
      filter: {
        fields: { type: { eq: "blog" } }
        frontmatter: { date: { gt: "2019" } }
      }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
            type
          }
          frontmatter {
            date
            title
          }
        }
      }
    }
  }
`;
